/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "headwindapp",
            "endpoint": "https://hhvje6ad7l.execute-api.ap-southeast-1.amazonaws.com/develop",
            "region": "ap-southeast-1"
        }
    ],
    "aws_content_delivery_bucket": "headwindapp-develop",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://dee0ewj6i1y42.cloudfront.net",
    "aws_dynamodb_all_tables_region": "ap-southeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Currency-develop",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Rides-develop",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Routes-develop",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Segments-develop",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Starred-develop",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Users-develop",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "Weather-develop",
            "region": "ap-southeast-1"
        }
    ]
};


export default awsmobile;
